@import "../../assets/css/var.scss";
.apply-wrap {
  .box-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    flex: 1;
    &:last-child {
      margin-bottom: 10%;
    }
  }
  .box {
    &-wrap {
      width: 180px;
      text-align: center;
      margin-top: 40px;
    }
    &-title {
      font-size: 24px;
      font-weight: 600;
      color: #2C3039;
      line-height: 33px;
      margin-top: 20px;
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }

  @include small-desktop-only {
    .box {
      &-wrap {
        width: 180px * $small-desktop-rate;
        text-align: center;
        margin-top: 40px * $small-desktop-rate;
        word-break: keep-all;
      }
      &-title {
        font-size: 24px;
        font-weight: 600;
        color: #2C3039;
        line-height: 33px;
        margin-top: 20px;
      }
      &-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include mid-desktop-only {
    .box {
      &-wrap {
        width: 180px * $mid-desktop-rate;
        text-align: center;
        margin-top: 40px * $mid-desktop-rate;
      }
      &-title {
        font-size: 24px;
        font-weight: 600;
        color: #2C3039;
        line-height: 33px;
        margin-top: 20px;
      }
      &-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-mobile {
    .box-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 30px;
      flex: 1;
      margin-bottom: 20%;
      &:last-child {
        margin-bottom: 15%;
      }
    }
    .box {
      &-wrap {
        width: 40%;
        text-align: center;
        margin-top: 20px;
        flex-shrink: 0;
      }
      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #2C3039;
        line-height: 22px;
        margin-top: 12px;
      }
      &-img {
        width: 110px;
      }
    }
  }
}