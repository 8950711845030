@import "./assets/css/var.scss";
:root {
  --languageFontRate: 1
}
body {
  font-family: PingFangSC-Regular, PingFang SC;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background: rgba(34, 116, 184, .5) !important;
}

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span {
  background: rgba(34, 116, 184, 1) !important;
}

.home-page-wrap {
  background: url("./assets/images/home_bg_pieces.png") no-repeat center / 100% auto,
  url("./assets/images/home_bg.png") no-repeat center / cover;
}
.defi-page-wrap {
  background: url("./assets/images/defi_slices.png") no-repeat center / 100% auto,
  url("./assets/images/defi_bg.png") no-repeat center / cover;
}
.apply-page-wrap {
  background: url("./assets/images/defi_apply_bg_s.png") no-repeat center / 100% auto;
}
.join-page-wrap {
  background: url("./assets/images/join_bd.png") no-repeat center / cover,
}
.community-page-wrap {
  background: url("./assets/images/com_bg.png") no-repeat center / 100% auto,
}
.research-page-wrap {
  background: url("./assets/images/research_bg.png") no-repeat center / 100% auto,
}
.fp-overflow {
  padding-top: 140px;
  flex: 1;
  display: flex;
}

.normal-content {
  width: 1200px;
  margin: 0 auto;
}

.flex {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.fp-watermark {
  display: none !important;
}
.text-right {
  text-align: right;
}

@include small-desktop-only {
  .normal-content {
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;
    flex: 1;
  }
  .fp-overflow {
    padding-top: 87px;
  }
}

@include mid-desktop-only {
  .fp-overflow {
    padding-top: 87px;
  }
  .normal-content {
    //padding: 0 40px;
  }
}

@include mobile-only {
  .normal-content {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .fp-overflow {
    padding-top: 42rem * $basicRem;
  }

  .home-page-wrap {
    background: url("./assets/images/m_home_slices.png") no-repeat top center / 100% auto,
    url("./assets/images/m_home_bg.png") no-repeat center / cover;
  }
  .defi-page-wrap {
    background: url("./assets/images/m_difi_s.png") no-repeat center / 100% auto,
    url("./assets/images/m_bg_difi.png") no-repeat center / cover;
  }
  .apply-page-wrap {
    background: url("./assets/images/m_apply_bg.png") no-repeat center / 100% auto,
  }
  .join-page-wrap {
    background: url("./assets/images/m_bg_join.png") no-repeat center / cover,
  }
  .community-page-wrap {
    background: url("./assets/images/m_com_bg.png") no-repeat center / 100% auto,
  }
  .research-page-wrap {
    background: url("./assets/images/m_research_bg.png") no-repeat center / 100% auto,
  }
}