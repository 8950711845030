@import "../../assets/css/var.scss";

.section-title-wrap {
  margin-top: 30px;
  .section-title {
    font-size: 40px;
    font-weight: 600;
    color: #2C3039;
    line-height: 45px;
  }
  .section-desc {
    font-size: 20px;
    font-weight: 400;
    color: rgba(44, 48, 57, .3);
    line-height: 20px;
  }
  .section-bar {
    width: 80px;
    height: 10px;
    background: #2174B8;
    border-radius: 2px;
    margin: 10px 0 20px 0;
  }

  @include small-desktop-only {
    .section-title {
      font-size: 40px * $small-desktop-rate;
      line-height: 56px * $small-desktop-rate;
    }
    .section-desc {
      font-size: 20px * $small-desktop-rate;
      line-height: 33px * $small-desktop-rate;
    }
    .section-bar {
      width: 80px * $small-desktop-rate;
      height: 10px * $small-desktop-rate;
    }
  }

  @include mid-desktop-only {

    .section-title {
      font-size: 40px * $mid-desktop-rate;
      line-height: 45px * $mid-desktop-rate;
    }
    .section-desc {
      font-size: 24px * $mid-desktop-rate;
      line-height: 30px * $mid-desktop-rate;
    }
    .section-bar {
      width: 80px * $mid-desktop-rate;
      height: 10px * $mid-desktop-rate;
    }
  }

  &-mobile {
    margin-top: 30px;
    .section-title {
      font-size: 20px;
      font-weight: 600;
      color: #2C3039;
      line-height: 20px;
    }
    .section-desc {
      font-size: 14px;
      font-weight: 400;
      color: rgba(44, 48, 57, .3);
      line-height: 14px;
    }
    .section-bar {
      width: 40px;
      height: 4px;
      background: #2174B8;
      border-radius: 2px;
      margin: 8px 0 12px 0;
    }
  }
}