@import "../../assets/css/var.scss";
.defi-wrap {
  position: relative;
  .card-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }
  .card {
    &-wrap {
      width: 270px;
      height: 360px;
      background: #FFFFFF;
      box-shadow: 0px 0px 15px 0px rgba(0,43,61,0.08);
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
    }
    &-title {
      font-size: calc(24px * var(--languageFontRate));
      font-weight: 600;
      color: #2C3039;
      line-height: 33px;
    }
    &-desc {
      font-size: calc(20px * var(--languageFontRate));
      font-weight: 400;
      color: rgba(44, 48, 57, .5);
      line-height: 30px;
      margin-top: 12px;
    }
    &-img {
      max-width: 50%;
      position: absolute;
      right: 20px;
      bottom: 30px;
      &-last {
        left: 50%;
        max-width: 70%;
        transform: translateX(-50%);
        bottom: 80px;
      }
    }
  }

  @include small-desktop-only {
    .card {
      &-content {
        left: 40px;
        right: 40px;
      }
      &-wrap {
        margin-right: 30px;
        height: 300px;
      }
      &-title {
        font-size: 18px;
        font-weight: 600;
        color: #2C3039;
        line-height: 22px;
      }
      &-desc {
        font-size: 14px;
        font-weight: 400;
        color: rgba(44, 48, 57, .5);
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }

  @include mid-desktop-only {
    .card {
      &-content {
        left: 40px;
        right: 40px;
      }
      &-title {
        font-size: 20px;
        font-weight: 600;
        color: #2C3039;
        line-height: 30px;
      }
      &-desc {
        font-size: 16px;
        font-weight: 400;
        color: rgba(44, 48, 57, .5);
        line-height: 24px;
        margin-top: 20px;
      }
    }
  }

  &-mobile {
    .card-content {
      margin-top: 20px;
    }
    .card {
      &-wrap {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(0,43,61,0.08);
        border-radius: 10px;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 20px;
      }
      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #2C3039;
        line-height: 22px;
      }
      &-desc {
        font-size: 14px;
        font-weight: 400;
        color: rgba(44, 48, 57, .5);
        line-height: 24px;
        margin-top: 5px;
      }
      &-img {
        max-width: 75px;
        flex-shrink: 0;
        margin-right: 30px;
        &-last {
          margin-top: 16px;
        }
      }
    }
  }
}