$origin-width: 1920px;
$max-width-breakpoint: 1200px;
$mid-width-breakpoint: 1400px;
$mobile-width-breakpoint: 1080px;
$pad-width-breakpoint: 768px;

$max-view-width: 90vw;

$small-desktop-rate: $max-width-breakpoint / $origin-width;
$mid-desktop-rate: $mid-width-breakpoint / $origin-width;
$basicRem: 1/16;

// screen < 1080
@mixin mobile-only {
  @media (max-width: ($mobile-width-breakpoint - 1)) {
    @content;
  }
}

@mixin pad-only {
  @media (min-width: ($pad-width-breakpoint)) and (max-width: ($mobile-width-breakpoint - 1)) {
    @content;
  }
}

// screen > 1080
@mixin desktop-only {
  @media (min-width: $mobile-width-breakpoint) {
    @content;
  }
}

// 1080 < screen < 1200
@mixin small-desktop-only {
  @media screen and (min-width: ($mobile-width-breakpoint)) and (max-width: ($max-width-breakpoint - 1)) {
    @content;
  }
}

// 1200 < screen < 1600
@mixin mid-desktop-only {
  @media screen and (min-width: ($max-width-breakpoint)) and (max-width: (1600px - 1)) {
    @content;
  }
}