@import '../../assets/css/var.scss';
.research-wrap {
  padding: 20px 0 20px;
  padding-top: 20vh;
  .research-img-top {
    display: none;
    width: 60%;
    img {
      display: block;
      width: 100%;
    }
  }
  .research {
    position: relative;
    margin-right: 20px;
    padding: 30px 400px 40px 40px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 43, 61, 0.08);
    border-radius: 10px;

    &-title {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2c3039;
      line-height: 56px;
    }
    &-sub {
      margin: 20px 0;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(44, 48, 57, 0.5);
      line-height: 34px;
    }
    &-desc {
      margin-bottom: 10px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2c3039;
      line-height: 34px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-img-right {
      position: absolute;
      right: -20px;
      top: 50%;
      width: 400px;
      height: 130%;
      transform: translateY(-50%);
      background: url(../../assets/images/research_icon_1.png) no-repeat;
      background-size: cover;
      border-radius: 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-mobile {
    padding: 40px 0 !important;
    .research-img-top {
      display: flex;
      justify-content: flex-end;
      img {
        margin-right: 10px;
        width: 60%;
        display: block;
        border-radius: 6px;
      }
    }
    .research {
      margin: -54px 25px 0;
      padding: 54px 20px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 15px 0px rgba(0, 43, 61, 0.08);
      border-radius: 10px;
      &-title {
        font-size: 20px;
        line-height: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2c3039;
      }
      &-sub {
        margin: 10px 0 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(44, 48, 57, 0.5);
      }
      &-desc {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 24px;
        color: #2c3039;
      }
      &-img-right {
        display: none;
      }
    }
  }
}
