@import "../../assets/css/var.scss";

.header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 140px;
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(4px);
  display: flex;
  padding: 0 calc((100% - 1200px)/2);
  align-items: center;

  .logo {
    width: 164px;
    height: 54px;
    margin-right: 26px;
    &-gkd {
      margin-right: 56px;
    }
  }

  @include small-desktop-only {
    height: 87px;
    .logo {
      width: 102px;
      height: 34px;
    }
    &-gkd {
      margin-right: 35px;
    }

    .language-select {
      right: 75px;
      height: 32px;
    }
  }

  @include mid-desktop-only {
    height: 87px;
    width: 100%;
    margin: 0 auto;
    padding: 0 calc((100% - 1200px)/2);
    justify-content: flex-start;
    .logo {
      width: 102px;
      height: 34px;
    }
    &-gkd {
      margin-right: 35px;
    }
    .language-select {
      right: 75px;
    }
  }

  // 移动端样式
  &-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 42rem * $basicRem;
    display: flex;
    align-items: center;
    //padding: 0 15rem * $basicRem;
    padding: 0 15px;
    background: rgba(255,255,255,0.5);
    .logo {
      width: 85rem * $basicRem;
      height: 27rem * $basicRem;
      margin-right: 20rem * $basicRem;
    }

    .language-select {
      right: calc(18rem / 16 + 15px + 20px);
      width: 36px;
      height: 21px;
      line-height: 21px;
      padding: 0;
      font-size: 12px;
      transition: none;
      .select-arrow {
        font-size: 8px;
        margin-left: 6px;
      }
    }

  }
}

.language-select {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 130px;
  width: 60px;
  height: 40px;
  background: #2174B8;
  border-radius: 4px;
  border-color: #2174B8;
  &-menu {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0,53,97,0.1);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #111111;
    .ant-dropdown-menu-item-selected, .ant-dropdown-menu-item-active {
      background: rgba(33, 116, 184, .1);
      border-radius: 5px;
      color: #111111;
    }
  }
  &:hover {
    background: #2174B8;
    border-color: #2174B8;
    .select-arrow {
      transform: rotate(180deg);
    }
  }
  .select-arrow {
    font-size: 10px;
  }
}