@import "../../assets/css/var.scss";
.join-wrap {
  display: flex;
  flex-direction: column;
  .join-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .join-card {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 0px rgba(0,43,61,0.08);
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 60px;
  }
  .join-form {
    justify-content: space-between;
  }
  .left {
    flex: 1;
    margin-right: 117px;
  }
  .resume {
    width: 568px;
    height: 142px;
    border-radius: 2px;
    border: 1px solid rgba(17, 17, 17, .1);
    padding: 35px 40px;
    display: flex;
    .select {
      display: none;
    }
    &-img {
      width: 94px;
      height: 72px;
      margin-right: 40px;
    }
    &-title {
      font-size: 15px;
      font-weight: 600;
      color: #2174B8;
      line-height: 22px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    &-desc {
      height: 44px;
      font-size: 15px;
      font-weight: 400;
      color: rgba(44, 48, 57, .3);
      line-height: 22px;
    }
    .file-name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .file-name, .file-desc {
      font-size: 15px;
      font-weight: 600;
      color: #2174B8;
      line-height: 22px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .re-icon {
      margin-right: 10px;
    }
  }
  .check-btn {
    width: 100%;
    height: 60px;
    background: linear-gradient(140deg, #71CADF 0%, #2274B8 100%);
    border-radius: 2px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }
  .ant-form-item-label label {
    font-size: 20px;
    font-weight: 600;
    color: #2C3039;
    line-height: 22px;
  }
  .ant-form-item-control-input-content input {
    border-radius: 2px;
    border: 1px solid rgba(17, 17, 17, .1);
    width: 100%;
  }
  .logo {
    width: 164px;
    height: 54px;
    margin-right: 26px;
  }
  .bottom {
    &-label {
      font-size: 15px;
      font-weight: 400;
      color: rgba(44, 48, 57, .5);
      line-height: 21px;
    }
    &-value {
      font-size: 15px;
      font-weight: 600;
      color: #2C3039;
      line-height: 21px;
    }
  }
  .address-item {
    align-content: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
    &:last-child {
      border-left: 1px solid rgba(44, 48, 57, .1);
    }
  }
  .bottom-line {
    width: 80%;
    height: 1px;
    background: rgba(44, 48, 57, .1);
    margin: 46px auto 40px;
  }
  .copy-right {
    font-size: 12px;
    font-family: Helvetica;
    color: rgba(44, 48, 57, .5);
    line-height: 14px;
    text-align: center;
  }

  @include mid-desktop-only {
    .join-content {
      justify-content: flex-start;
    }
    .join-card {
      border-radius: 10px;
      padding: 25px;
    }
  }
  &-mobile {
    .join-card {
      background: #FFFFFF;
      box-shadow: 0px 0px 15px 0px rgba(0,43,61,0.08);
      border-radius: 10px;
      width: 100%;
      padding: 16px;
    }
    .join-form {
      flex-direction: column;
    }

    .resume {
      width: 100%;
      height: 124px;
      border-radius: 2px;
      border: 1px solid rgba(17, 17, 17, .1);
      padding: 16px 22px;
      .select {
        display: none;
      }
      &-img {
        width: 45px;
        height: 34px;
        margin-right: 20px;
        &-wrap {
          display: inline-block;
        }
      }
      &-title {
        font-size: 14px;
        font-weight: 600;
        color: #2174B8;
        line-height: 22px;
        margin-bottom: 10px;
        cursor: pointer;
        display: inline-block;
      }
      &-desc {
        font-size: 15px;
        font-weight: 400;
        color: rgba(44, 48, 57, .3);
        line-height: 22px;
        margin-top: 10px;
      }
      .file-name {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .file-name, .file-desc {
        font-size: 15px;
        font-weight: 600;
        color: #2174B8;
        line-height: 22px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .re-icon {
        margin-right: 10px;
      }
      &-file {
        display: inline-block;
      }
    }

    .check-btn {
      width: 100%;
      height: 40px;
      background: linear-gradient(140deg, #71CADF 0%, #2274B8 100%);
      border-radius: 2px;
      border: none;
      font-size: 16px;
      font-weight: 600;
    }
  }
}