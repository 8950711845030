@import "../../assets/css/var.scss";
.home-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .epi-image {
    width: 384px;
    height: 107px;
  }
  .epi-text {
    font-size: calc(26px * var(--languageFontRate));
    font-weight: 400;
    color: rgba(44, 48, 57, .9);
    line-height: 42px;
    margin-top: 69px;
  }

  @include small-desktop-only {
    .epi-image {
      width: 240px;
      height: 67px;
    }
    .epi-text {
      font-size: calc(20px * var(--languageFontRate));
      margin-top: 30px;
    }
  }

  &-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    .epi-image {
      width: 200px;
    }
    .epi-text {
      color: rgba(44, 48, 57, .9);
      margin-top: 19rem * $basicRem;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      margin-bottom: 60px;
    }
  }
}

