@import "../../assets/css/var.scss";

.menu-wrap {
  font-family: PingFangSC-Semibold, PingFang SC;
  .menu-list {
    display: flex;
  }
  .menu-item {
    margin-right: 20px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(44, 48, 57, .5);
    align-items: center;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &-active, &:hover {
      position: relative;
      color: #2C3039;
      &:after {
        content: '';
        position: absolute;
        width: 28px;
        transform: translateX(-50%);
        left: 50%;
        bottom: -6px;
        height: 6px;
        background: #2174B8;
        border-radius: 2px;
      }
    }
  }
  @include small-desktop-only {
    margin-right: 30px;
    .menu-item {
      font-size: 16px;
      &-active, &:hover {
        &:after {
          height: 3px;
          bottom: -3px;
        }
      }
    }
  }

  &-mobile {
    flex: 1;
    text-align: right;
  }
}


.m-close {
  width: 12rem * $basicRem;
  height: 12rem * $basicRem;
  margin-bottom: 27rem * $basicRem;
}

.menu-icon {
  width: 18rem * $basicRem;
  height: 14rem * $basicRem;
}

.m-epi-menu {
  .ant-drawer-content {
    background: rgba(255,255,255,0.9);
    backdrop-filter: blur(4px);
  }
}

.menu-list-mobile {
  .menu-item {
    width: 100%;
    height: 60rem * $basicRem;
    font-size: 14px;
    font-weight: 600;
    line-height: 60rem * $basicRem;
    padding-left: 15rem * $basicRem;
    color: #2C3039;
    &-active, &:hover {
      background: rgba(0, 118, 190, .1);
      position: relative;
      color: #2174B8;
      border-right: 6rem * $basicRem solid #0076BE;
    }
  }
}