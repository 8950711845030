@import "../../assets/css/var.scss";
.community-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .community-content {
    display: flex;
  }
  .community-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .community-img {
    width: 488px;
  }
  .community-right {
    flex: 1;
  }

  .text-item {
    margin-top: 30px;
    &-title {
      font-size: 24px;
      font-weight: 600;
      color: #2C3039;
      line-height: 33px;
    }
    &-desc {
      font-size: 20px;
      font-weight: 400;
      color: rgba(44, 48, 57, .5);
      line-height: 30px;
      word-break: break-all;
    }
  }

  .bottom-bar {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 0px rgba(0,43,61,0.08);
    border-radius: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: translateY(-20%);
    &-icon {
      width: 63px;
      height: 63px;
      vertical-align: middle;
      margin-right: 25px;
    }
    &-text {
      font-size: calc(24px * var(--languageFontRate));
      font-weight: 600;
      color: #2C3039;
      line-height: 33px;
      vertical-align: middle;
    }
  }

  .section-title-wrap {
    margin-top: 0;
  }

  @include small-desktop-only {
    .community-img {
      &-big {
        width: 417px * $small-desktop-rate;
        height: 417px * $small-desktop-rate;
        right: 210px * $small-desktop-rate;
        bottom: 70px * $small-desktop-rate;
      }
      &-mini {
        width: 226px * $small-desktop-rate;
        height: 226px * $small-desktop-rate;
        right: 116px * $small-desktop-rate;
      }
    }
    .text-item {
      margin-top: 30px * $small-desktop-rate;
      &-title {
        font-size: 20px;
        font-weight: 600;
        color: #2C3039;
        line-height: 33px * $small-desktop-rate;
      }
      &-desc {
        font-size: 16px;
        font-weight: 400;
        color: rgba(44, 48, 57, .5);
        line-height: 30px;
        word-break: break-all;
      }
    }
  }

  @include mid-desktop-only {
    .section-title-wrap {
      margin-top: 0;
    }
    .community-img {
      width: 550px * $mid-desktop-rate;
    }
    .text-item {
      margin-top: 30px * $mid-desktop-rate;
      &-title {
        font-size: 30px * $mid-desktop-rate;
        line-height: 33px * $mid-desktop-rate;
        margin-bottom: 10px;
      }
      &-desc {
        font-size: 24px * $mid-desktop-rate;
        line-height: 36px * $mid-desktop-rate;
      }
    }
  }

  &-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .community-left {
      position: relative;
      margin: 16px 0;
      text-align: center;
    }
    .community-img {
      width: 162px;
    }

    .text-item {
      margin-top: 20px;
      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #2C3039;
        line-height: 24px;
      }
      &-desc {
        font-size: 14px;
        font-weight: 400;
        color: rgba(44, 48, 57, .5);
        line-height: 22px;
        word-break: break-all;
      }
    }

    .bottom-bar {
      text-align: center;
      width: 100%;
      box-shadow: 0px 0px 15px 0px rgba(0,43,61,0.08);
      border-radius: 5px;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      padding: 12px 15px;
      margin-bottom: 10px;
      &-icon {
        width: 30px;
        height: 30px;
        margin-bottom: 6px;
      }
      &-text {
        text-align: center;
        display: block;
        //font-size: 14px;
        //word-break: break-all;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: #2C3039;
      }
    }
  }
}